/**
 * Abstracted away browser-specific global dependencies (window and document).
 * NodeJS common global objects, such as:
 * Math, JSON, Promise, etc., are not included here.
 */

export const getWindow = () => window;

export const storage = () => getWindow().localStorage;

export const onDOMContentLoaded = (...args) => getWindow().addEventListener('DOMContentLoaded', ...args);

/**
 * Updates location of the current browser page.
 * @param {string} url - New location we want our page transit to.
 * @returns {void} No return value.
 */
export const changeLocation = (url) => {
  getWindow().location.href = url;
};

export const openWindow = (...args) => getWindow().open(...args);

export const encodeURIComponent = (...args) => getWindow().encodeURIComponent(...args);

export const decodeURIComponent = (...args) => getWindow().decodeURIComponent(...args);

export const btoa = (...args) => getWindow().btoa(...args);

export const atob = (...args) => getWindow().atob(...args);

export const getDocument = () => document;

export const docReadyState = () => getDocument().readyState;

export const getElementById = (...args) => getDocument().getElementById(...args);

export const createElement = (...args) => getDocument().createElement(...args);
