/**
 * Creates new EventEmitter prototype.
 * @returns {EventEmitter} Event emitter prototype instance.
 */
const CreateEventEmitterProto = () => ({
  /**
   * Storage of the registered events.
   */
  get events() {
    if (typeof this.eventsStorage !== 'object') {
      this.eventsStorage = {};
    }
    return this.eventsStorage;
  },

  /**
   * Subscribe to event.
   * @param {string} event - Name of the event you want to subscribe to.
   * @param {Function} listener - Event handler that will be called when event occures.
   * @returns {void} No return value.
   */
  on(event, listener) {
    if (typeof this.events[event] !== 'object') {
      this.events[event] = [];
    }

    this.events[event].push(listener);
  },

  /**
   * Subscribe to event for the first event occurance only.
   * @param {string} event - Name of the event you want to subscribe to.
   * @param {Function} listener - Event handler that will be called when event occures.
   * @returns {void} No return value.
   */
  once(event, listener) {
    this.on(event, function handler(...args) {
      this.removeListener(event, handler);
      listener.apply(this, args);
    });
  },

  /**
   * Removes event listener for particular event.
   * @param {string} event - Name of the event you want to unsubscribe from.
   * @param {Function} listener - Event handler that is currently subscribed to the event.
   * @returns {void} No return value.
   */
  removeListener(event, listener) {
    if (typeof this.events[event] === 'object') {
      const index = this.events[event].indexOf(listener);

      if (index > -1) {
        this.events[event].splice(index, 1);
      }
    }
  },

  /**
   * Emits event with some payload.
   * @param {string} event - Name of the event you want to occure.
   * @param {any} data - Payload that should be passed to each subscribed listener function.
   * @returns {void} No return value.
   */
  emit(event, data) {
    if (typeof this.events[event] === 'object') {
      const listeners = this.events[event].slice();

      listeners.forEach(listener => listener.apply(this, [{ name: event, data }]));
    }
  },
});

/**
 * Prefer this function over EventEmitter inheritance to avoid SOLID violation.
 * Decorates object with EventEmitter capabilities.
 * @param {Object} self - Instance of the object you want to eventify.
 * @returns {Object} Returns decorated event-drive object that was passed as an argument.
 */
export const eventify = self =>
  Object.assign(self, CreateEventEmitterProto());

/**
 * Use class inheritance to save memory.
 * Declares event-based object type. This type can be used if you want
 * to add event-driven behavior to your object/entity.
 *
 * Usage:
 * class BaseClass extends EventEmitter { ... }
 */
class EventEmitter {

}

EventEmitter.prototype = CreateEventEmitterProto();

export default EventEmitter;
