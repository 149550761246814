import { createUrlQueryStr } from './helpers';

import {
  createOption,
  appendOption,
  initLinkButton,
  initSelect,
  initPicker,
  appendHtml,
  hideElement,
  getSelectTemplate,
  getTextInputTemplate,
  getButtonTemplate,
  toggleElement,
  addClass,
  removeClass,
  uniqueID,
  openBlankWindow,
  changeLocation,
  getDatePickerContainerTemplate,
  getWidgetItemTemplate,
  setBrandColor,
  addClassOnResizeById,
} from './dom';

import {
  ROOT_CLASS,
  WIDGET_ITEM_LIST_CONTAINER,
  DATEPICKER_CONTAINER_CLASS_NAME,
  DATEPICKER_INPUT_WRAPPER_ID,
  DATE_QUERY_PARAM_FORMAT,
  DATE_INPUT_FORMAT,
  CHECK_IN_CLASS_NAME,
  CHECK_OUT_CLASS_NAME,
  CITIES_CLASS_NAME,
  GUESTS_CLASS_NAME,
  BUTTON_CLASS_NAME,
  INPUT_HAS_VALUE_CLASS_NAME,
  FOCUSED_CLASS_NAME,

  // Widget events
  EVENT_SUBMIT_SEARCH,
  EVENT_CITY_CHANGED,
  EVENT_GUESTS_CHANGED,
  EVENT_DATES_CHANGED,
} from './constants';

import { eventify } from './eventEmitter';

/**
 * @param {string} param0.siteUrl - external website url.
 * @param {string} param0.paramsStr - url parameters.
 * @param {string} param0.lang - lang parameter.
 * @returns {string} External website listings page with parameters.
 */
export const getListingsUrl = ({ siteUrl, paramsStr, lang = null }) => {
  return `https://${siteUrl}${lang ? `/${lang}` : ''}/properties${paramsStr}`;
}

/**
 * Widget class declaration.
 */
export default class {
  /**
   * Widget class async constructor function.
   * @param {Api} api - Api instance.
   * @param {Object} config - Configuration object.
   * @param {Object} translations - Translations object.
   * @returns {Promise<Widget>} Asynchronously, returns instance
   * of the Widget when all inner controls are initialized.
   */
  constructor(api, config, translations) {
    eventify(this);// apply EventEmitter on the Widget class instance
    this.config = config;
    this.api = api;
    this.params = {
      city: null, guests: null, minOccupancy: null, checkOut: null, checkIn: null,
    };
    this.translations = translations;
    this.selectedWrapperId = null;
    this.citiesAreHidden = false;
    toggleElement(this.config.rootElementID, false);
    // TODO: show loading spinner here?
    addClass(this.config.rootElementID, ROOT_CLASS);
    addClass(this.config.rootElementID, WIDGET_ITEM_LIST_CONTAINER);

    // IMPORTANT: order matters
    const controls = ['citiesCtrl', 'pickerCtrl', 'guestsCtrl', 'submitCtrl', 'brandColor'];
    const resolveControls = () => Promise.all(
        controls.map((control) => {
          return Promise
              .resolve(this[control]())
              .catch((e) => console.error(e))
        })
    )
        .then(() => addClassOnResizeById(this.config.rootElementID, 'small-size', {
          // reduce resize width if there is no dropdown
          reducer: [
            { /* cities dropdown - */ active: this.citiesAreHidden, value: 250 },
          ],
          width: 782,
        }))
        .then(() => {
          toggleElement(this.config.rootElementID, true);
        })
        .then(() => this);

    return this.config.noApi ? resolveControls() : this.api.getWidgetSettings()
        .then(() => this)
        .catch((error) => {
          console.error(error.message);
          return this;
        })
        .finally(resolveControls)
  }

  /**
   * Helper wrapper to simplify appendHtml function invocation.
   * @param {string} html - HTML content that has to be passed into the widget's root element.
   * @param {stirng} className - CSS class name to be added to the new element.
   * @returns {void} No return value.
   */
  appendToRoot(html, className) {
    // cover each new element with `${WIDGET_ITEM_CLASS_NAME}` container
    return appendHtml(this.config.rootElementID, getWidgetItemTemplate(html, className));
  }

  /**
   * Creates cities select control inside the widget.
   * @returns {Promise} Promise to resolve the control.
   */
  citiesCtrl() {
    if (this.config.disableCities === true) return Promise.resolve();

    const id = uniqueID();
    const citiesPlaceholder = this.translations.citiesPlaceholder;

    /**
     * Build cities select control.
     * @param {Array<string>} cities - Cities that will appear in the cities select control.
     * @returns {void} No return value.
     */
    const init = (cities) => {
      const citiesArray = cities.results || cities;
      if (citiesArray.length < 2 && !!this.config.removeIfSingleCity) {
        this.citiesAreHidden = true;

        hideElement(id);
        return;
      }

      const hasCountry = citiesArray.some(c => c.country);
      const citiesToMap = hasCountry ? citiesArray.map(c => c.city) : citiesArray;
      citiesToMap.forEach(c => appendOption(id, createOption(c)));
      initSelect(id, citiesPlaceholder, (val) => {
        if (hasCountry) {
          const { country, city } = citiesArray.find(c => c.city === val);
          this.params.city = city;
          this.params.country = country;
        } else {
          this.params.city = val;
        }
        this.emit(EVENT_CITY_CHANGED, { ...this.params });
      });
    };

    this.appendToRoot(getSelectTemplate(id, citiesPlaceholder), CITIES_CLASS_NAME);

    if (this.config.cities) return init(this.config.cities);

    init(this.api.widgetSettings.cities);
  }

  /**
   * Creates guests number select control inside the widget.
   * @returns {Promise} Promise to resolve the control.
   */
  guestsCtrl() {
    if (this.config.disableGuests === true) return Promise.resolve();

    const id = uniqueID();

    /**
     * Build guests select control.
     * @param {number} maxGuests - Maximum number of guests available through all listings.
     * @returns {void} No return value.
     */
    const init = (maxGuests) => {
      for (let i = 1; i <= maxGuests; i += 1) {
        appendOption(id, createOption(i));
      }
      const placeholder = this.translations.guestsPlaceholder;
      initSelect(id, placeholder, (val) => {
        this.params.guests = val;
        this.params.minOccupancy = val;
        this.emit(EVENT_GUESTS_CHANGED, { ...this.params });
      });
    };

    const guestsPlaceholder = this.translations.guestsLabel;

    this.appendToRoot(getSelectTemplate(id, guestsPlaceholder), GUESTS_CLASS_NAME);

    if (this.config.maxGuests) return init(this.config.maxGuests);

    init(this.api.widgetSettings.maxGuests);
  }

  /**
   * Creates guests number select control inside the widget.
   * @returns {Promise} Promise to resolve the control.
   */
  pickerCtrl() {
    if (this.config.disableDates === true) return;

    const inputOneId = uniqueID();
    const inputTwoId = uniqueID();
    const wrapperOneId = `${DATEPICKER_INPUT_WRAPPER_ID}${inputOneId}`;
    const wrapperTwoId = `${DATEPICKER_INPUT_WRAPPER_ID}${inputTwoId}`;

    const checkInPlaceholder = this.translations.checkInPlaceholder;
    const checkOutPlaceholder = this.translations.checkOutPlaceholder;

    // render datepicker container and date inputs inside it
    this.appendToRoot(getDatePickerContainerTemplate(`
            ${getTextInputTemplate(inputOneId, CHECK_IN_CLASS_NAME, checkInPlaceholder)}
            ${getTextInputTemplate(inputTwoId, CHECK_OUT_CLASS_NAME, checkOutPlaceholder)}
        `));

    // init date picker
    const datePickerTranslations = this.translations.datePickerLocaleTranslations;
    const lang = this.config.locale;
    initPicker(
      inputOneId,
      inputTwoId,
      DATEPICKER_CONTAINER_CLASS_NAME,
      DATE_INPUT_FORMAT,
      lang,
      datePickerTranslations,
      (start, end) => {
        this.params.checkIn = start && start.format(DATE_QUERY_PARAM_FORMAT);
        this.params.checkOut = end && end.format(DATE_QUERY_PARAM_FORMAT);

        if (start) {
          addClass(wrapperOneId, INPUT_HAS_VALUE_CLASS_NAME);
        }

        if (end) {
          addClass(wrapperTwoId, INPUT_HAS_VALUE_CLASS_NAME);
        }

        if (start && !end) {
          this.focus(wrapperTwoId, this.selectedWrapperId);
        }

        this.emit(EVENT_DATES_CHANGED, { ...this.params });
      },
      () =>
        [wrapperOneId, wrapperTwoId].forEach(this.unfocus.bind(this)),
    );

    initLinkButton(inputOneId, () => {
      this.focus(wrapperOneId, wrapperTwoId);
    });
    initLinkButton(inputTwoId, () => {
      this.focus(wrapperTwoId, wrapperOneId);
    });
  }

  focus(toFocus, toUnfocus) {
    addClass(toFocus, FOCUSED_CLASS_NAME);
    removeClass(toUnfocus, FOCUSED_CLASS_NAME);
    this.selectedWrapperId = toFocus;
  }

  unfocus(toUnfocus) {
    removeClass(toUnfocus, FOCUSED_CLASS_NAME);
    this.selectedWrapperId = null;
  }

  /**
   * Open URL in new tab or in the same - based on openInNewTab config setting.
   * @param {string} url - URL address to which the page will be redirected.
   * @returns {void} No return value.
   */
  goToUrl(url) {
    if (this.config.openInNewTab) {
      openBlankWindow(url);
    } else {
      changeLocation(url);
    }
  }

  /**
   * Creates submit button control inside the widget.
   * @returns {Promise} Promise to resolve the control.
   */
  submitCtrl() {
    const id = uniqueID();
    const btnPlaceholder = this.translations.searchBtnPlaceholder;

    this.appendToRoot(getButtonTemplate(id, btnPlaceholder, BUTTON_CLASS_NAME));

    initLinkButton(id, () => {
      const lang = this.api.widgetSettings.isLocaleEnabled
        ? this.config.locale
        : null;
      const url = getListingsUrl({
        lang,
        siteUrl: this.config.siteUrl,
        paramsStr: createUrlQueryStr(this.params),
      });

      this.emit(EVENT_SUBMIT_SEARCH, { ...this.params });
      this.goToUrl(url);
    });
  }

  /**
   * Get brand color and set it up globally for the entire widget.
   * @returns {Promise} Promise to resolve the control.
   */
  brandColor() {
    if (this.config.color) return setBrandColor(this.config.rootElementID, this.config.color);

    setBrandColor(this.config.rootElementID, this.api.widgetSettings.color);
  }
}
