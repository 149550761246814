export const WIDGET_NAME = 'GuestySearchBarWidget';// Important: don't change it - this string is used in dashboard to dynamically form loader js script

export const DEFAULT_ROOT_ID = 'search-widget_IO312PWQ';

export const ROOT_CLASS = 'guesty-root-element';

export const DATE_INPUT_FORMAT = 'MMM D, YYYY';

export const DATE_QUERY_PARAM_FORMAT = 'YYYY-MM-DD';

export const CHECK_IN_CLASS_NAME = '__super-input check-in';

export const CHECK_OUT_CLASS_NAME = '__super-input check-out';

export const CITIES_CLASS_NAME = 'cities-dropdown';

export const CITIES_PLACEHOLDER = 'Destination';

export const GUESTS_PLACEHOLDER = 'Guests';

export const CHECK_IN_PLACEHOLDER = 'Check in';

export const CHECK_OUT_PLACEHOLDER = 'Check out';

export const SEARCH_PLACEHOLDER = 'Search';

export const SELECT_OPTION_PLACEHOLDER = 'Select an option...';

export const GUESTS_CLASS_NAME = 'guests-dropdown';

export const BUTTON_CLASS_NAME = 'guesty-search-submit-btn';

export const WIDGET_ITEM_LIST_CONTAINER = 'guesty-widget__container';

export const WIDGET_ITEM_CLASS_NAME = 'guesty-widget__item';

export const DATEPICKER_CONTAINER_CLASS_NAME = 'guesty-search-widget__datepicker';

export const DATEPICKER_INPUT_WRAPPER_ID = 'date-picker-wrapper';

export const INPUT_HAS_VALUE_CLASS_NAME = 'has-value';

export const FOCUSED_CLASS_NAME = 'guesty-focused';

// Widget events
export const EVENT_SUBMIT_SEARCH = 'submitSearch';
export const EVENT_CITY_CHANGED = 'cityChanged';
export const EVENT_GUESTS_CHANGED = 'guestsChanged';
export const EVENT_DATES_CHANGED = 'datesChanged';
