import { storage } from './browser';

import {
  daysPassedSince,
  parseBase64StringToObject,
  stringifyObjectToBase64,
  base64Encode,
} from './helpers';

export default {
  /**
   * Retrieves data from cache.
   * @param {string} key - Unique key that identifies the stored data.
   * @param {number} cachePeriod - Number of days the cached data is trieted as valid.
   * @returns {any} Cached data.
   */
  getItem(key, cachePeriod = 3) {
    try {
      const base64StrKey = base64Encode(key);
      const base64StrData = storage().getItem(base64StrKey);

      const { setAt, data } = parseBase64StringToObject(base64StrData);

      /**
       * Keep cache for cachePeriod days.
       * After cachePeriod days, cache is ignored and updated accordingly.
       */
      if (daysPassedSince(new Date(setAt), new Date(), cachePeriod)) { return null; }

      return data;
    } catch (err) {
      return null;
    }
  },
  /**
   * Stores data in cache.
   * @param {string} key - Unique key that will be used to identify stored data.
   * @param {*} data - Data you want to be cached.
   * @returns {void} No return value.
   */
  setItem(key, data) {
    const setAt = new Date();
    storage().setItem(base64Encode(key), stringifyObjectToBase64({ setAt, data }));
  },
};
