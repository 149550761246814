import { translations } from "./translations";
import {
    CHECK_IN_PLACEHOLDER,
    CHECK_OUT_PLACEHOLDER,
    GUESTS_PLACEHOLDER,
    CITIES_PLACEHOLDER,
    SEARCH_PLACEHOLDER,
    SELECT_OPTION_PLACEHOLDER,
} from "../constants";

export class Translations {
    constructor(lang) {
        const DEFAULT_LANG = 'en';
        this.translations = translations.hasOwnProperty(lang)
            ? translations[lang]
            : translations[DEFAULT_LANG];
    }

    get datePickerLocaleTranslations() {
        return this.translations.datepicker.locale;
    }

    get checkInPlaceholder() {
        return (this.translations.datepicker && this.translations.datepicker.checkIn)
            ? this.translations.datepicker.checkIn
            : CHECK_IN_PLACEHOLDER;
    }

    get checkOutPlaceholder() {
        return (this.translations.datepicker && this.translations.datepicker.checkOut)
            ? this.translations.datepicker.checkOut
            : CHECK_OUT_PLACEHOLDER;
    }

    get guestsLabel() {
        return (this.translations.guests && this.translations.guests.label)
            ? this.translations.guests.label
            : GUESTS_PLACEHOLDER;
    }

    get guestsPlaceholder() {
        return this.translations.guests.placeholder || SELECT_OPTION_PLACEHOLDER;
    }

    get citiesPlaceholder() {
        return (this.translations.cities && this.translations.cities.placeholder)
            ? this.translations.cities.placeholder
            : CITIES_PLACEHOLDER;
    }

    get searchBtnPlaceholder() {
        return this.translations.searchBtn || SEARCH_PLACEHOLDER;
    }

    get errors() {
        return this.translations.errors;
    }
}
