import moment from 'moment';// is required by lightpick
// import 'moment/locale/fr';
import Api from './api';
import Widget from './widget';
import { domReady } from './dom';

import { DEFAULT_ROOT_ID } from './constants';

import {
  validateEnvironment,
  validateConfig,
} from './helpers';

import { Translations } from "./translations";

/**
 * Creates Widget instance previously validating configuration object and environment.
 * @param {Object} inputConfig - Configuration object.
 * @param {string} inputConfig.siteUrl - This is used to determine which website should this widget
 * refer to. Pass you PMWebsite domain here.
 * @param {string} inputConfig.rootElementID - ID of the DOM element this widget
 * should be attached/placed to. This element will remain outer container for the widget itself.
 * If element with such ID doesn't exist - widget will not be initialized.
 * @param {boolean} inputConfig.openInNewTab - Set to false if you want to open PMWebsite
 * in the same browser window. IMPORTANT - in case this widget is loaded within iFrame,
 * setting openInNewTab to false will not work and will cause issues.
 * @param {boolean} inputConfig.removeIfSingleCity - If true, it removes cities select
 * in case there is one or no cities.
 * @param {boolean} inputConfig.disableCities - If true, removes cities select.
 * @param {boolean} inputConfig.disableGuests - If true, removes guests select.
 * @param {boolean} inputConfig.disableDates - If true, removes dates picker.
 * @param {boolean} inputConfig.noApi - If true, widget will not make any calls to
 * guesty's services to retrieve data - this will increase widget loading time.
 * IMPORTANT - in that case data will be taken from maxGuests, cities, color configuration
 * properties. Those properties automatically become required.
 * @param {number} inputConfig.maxGuests - Max number of guests possible on listings
 * that are available on the PMWebsites.
 * @param {Array<string>} inputConfig.cities - List of all available cities on PMWebsite.
 * @param {string} inputConfig.color - Color that will be used to style the widget.
 * @returns {Promise<Widget>} Instance of the Widget.
 */

export const create = (inputConfig = {}) => {
  try {
    // default config values
    const config = {
      // required
      siteUrl: null,
      rootElementID: DEFAULT_ROOT_ID,

      // optional
      openInNewTab: true,
      removeIfSingleCity: true,
      disableCities: false,
      disableGuests: false,
      disableDates: false,
      noApi: false,
      noCache: false,
      maxGuests: null,
      cities: null,
      color: null,
      locale: 'en',

      ...inputConfig,
    };

    const translations = new Translations(config.locale);

    validateEnvironment(translations);
    validateConfig(config, translations);
    moment.locale(config.locale || 'en');

    return Promise.all([domReady(), new Api(config)])
        .then(([, api]) => api)
        .then((api) => new Widget(api, config, translations));
  } catch (err) {
    return Promise.reject(err);
  }
};

export default { create };
