import widgetBuilder from './widgetBuilder';
import { WIDGET_NAME } from './constants';
import { isNodeJsEnvironment } from './helpers';
import { getWindow } from './browser';
import './styles/index.scss';

/**
 * This is pattern to define widget in different environments.
 * @param {Object} root - Browser root element, pass window ref here.
 * @param {Function} factory - Returnes widget.
 * @param {string} name - Name of the widget that will be used globally.
 * @returns {void} No return value.
 */
const initModuleGlobally = (root, factory, name) => {
  if (isNodeJsEnvironment()) {
    module.exports = factory();
  } else {
    root[name] = factory(); // eslint-disable-line no-param-reassign
  }
};

export default initModuleGlobally;

(initModuleGlobally(getWindow(), () => widgetBuilder, WIDGET_NAME));
